import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import Toggle from "./Toggle";
import Gplay from "../../images/google-icon.png";
import Apple from "../../images/apple-icon.png";
import { switchToPub, getAssignedAgentDetails } from "../../app/api";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Card } from "reactstrap";
import DefaultAgentImage from "../../assets/images/user-agent.jpg";
import { Icon } from "../../components/Component";
import {UserAvatar} from "../../components/Component"

const Sidebar = ({ fixed, theme, className, sidebarToggle, mobileView, ...props }) => {
  const [collapseSidebar, setSidebar] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [agentDetails, setAgentDetails] = useState({
    agentId: "",
    agentName: "",
    agentEmail: "",
    contact: "",
    agentSkype: "",
    agentTelegram: "",
    agentProfileImage: "",
    resCode: ""
  });

  const urole = localStorage.getItem('urole');
  const prm = localStorage.getItem('perms');

  const toggleCollapse = () => {
      setSidebar(!collapseSidebar);
  };

  const changeAccount = async ( ) => {
    let res = await switchToPub();
    return res;
  }

  const getAgentDetails = async () => {
    let uid = localStorage.getItem("uid");
    let res = await getAssignedAgentDetails({
      uid: uid,
    });
    if (res.code == 200) {
      setAgentDetails({
        agentId: res.data.agent_id,
        agentName: res.data.name,
        agentEmail: res.data.email,
        contact: res.data.contact_no,
        agentSkype: res.data.skype_id,
        agentTelegram: res.data.telegram_id,
        agentProfileImage: res.data.profile_image,
        resCode: res.code
      });
    }
  };

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "is-compact": collapseSidebar,
    "has-hover": collapseSidebar && mouseEnter,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  useEffect(() => {
    getAgentDetails();
  }, []);

  return (
    <div className={classes} >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-menu-trigger">
          <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none mr-n2" icon="arrow-left" click={sidebarToggle} />
          <Toggle
            className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${
              collapseSidebar ? "compact-active" : ""
            }`}
            click={toggleCollapse}
            icon="menu"
          />
        </div>
        <div className="nk-sidebar-brand">
          <Logo />
        </div>
      </div>
      <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <SimpleBar className="nk-sidebar-menu">
          <Menu sidebarToggle={sidebarToggle} mobileView={mobileView}/>

          {prm == '9999' && 
          <>
          {agentDetails.resCode === 200 ?
            <div className="card-inner agent-manager-card">
              <h6 className="nk-menu-heading mb-3 agent-manager-heading pl-3" style={{fontSize:"medium", width:"106%"}}><Icon name="user-fill-c" style={{fontSize:"18px"}}></Icon> &nbsp; Your Ad Manager</h6>
              <div className="user-card mb-2" style={{justifyContent:"center"}}>
                {agentDetails.agentProfileImage !== null 
                  ? <UserAvatar className={"lg"} image={`https://services.7searchppc.com/agent/profile_image/`+agentDetails.agentProfileImage} theme="primary"></UserAvatar>
                  : <UserAvatar image={DefaultAgentImage} theme="primary"></UserAvatar>
                }
              </div>
              <div className="user-info mb-2">
                <span className="lead-text agent-manager-name">{agentDetails.agentName}</span>
                {agentDetails.agentId !== null &&
                  <span className="sub-text" style={{justifyContent:"center", color:"#3b82f6"}}>{agentDetails.agentId}</span>
                }
              </div>
              <div className="agent-manager-contact-scroll ml-2">
                {agentDetails.agentEmail !== null &&
                  <span className="agent-manager-contact email-wrap-custom">
                    <Icon name="emails" style={{color:"black"}}></Icon> &nbsp;
                    <a href={`mailto:${agentDetails.agentEmail}`} style={{color:"black", cursor:"pointer"}} title={agentDetails.agentEmail}>
                      {agentDetails.agentEmail}
                    </a>
                  </span>
                }
                {agentDetails.contact !== null &&
                  <span className="agent-manager-contact">
                    <Icon name="mobile" style={{color:"black"}}></Icon> &nbsp;
                    <a href={`tel:${agentDetails.contact}`} style={{color:"black", cursor:"pointer"}} title={agentDetails.contact}>
                      {agentDetails.contact}
                    </a>
                  </span>
                }
                {agentDetails.agentSkype !== null && 
                  <span className="agent-manager-contact">
                    <Icon name="skype" style={{color:"black"}}></Icon> &nbsp;
                    <a href={`skype:${agentDetails.agentSkype}?chat`} style={{color:"black", cursor:"pointer"}} title={agentDetails.agentSkype}>
                      {agentDetails.agentSkype}
                    </a>
                  </span>
                }
                {agentDetails.agentTelegram !== null && 
                  <span className="agent-manager-contact">
                    <Icon name="telegram" style={{color:"black"}}></Icon> &nbsp;
                    <a href={`https://t.me/${agentDetails.agentTelegram}`} target="_blank" style={{color:"black", cursor:"pointer"}} title={agentDetails.agentTelegram}>
                      {agentDetails.agentTelegram}
                    </a>
                  </span>
                }
              </div>
            </div>
          : ""
          }
          
          <div style={{paddingLeft:42, marginTop:15}}>
          <Button 
              color="#0466b0"
              className="mb-4 pl-1 calendly-meet-btn-custom" 
              onClick={ async () => {
                window.open("https://calendly.com/7searchppc/schedule-meeting", "_blank");
              }}  
            >
              <Icon name="calendar"></Icon>  &nbsp; Schedule Meeting
            </Button>

            {urole == 3 ?
              <Button color="primary" type="submit" onClick={ async () => {
                let res = await changeAccount();
                if(res) {
                  let str = window.btoa(res.token + "|" + res.email );
                  let type = window.btoa("advertiser");
                  localStorage.removeItem('accessToken');
                  // http://localhost:3001/
                  window.location.href = "https://advertiser.7searchppc.com/auto-auth/" + str +"/advertiser"
                  // window.location.href = "https://advertiser.7searchppc.in/auto-auth/" + str +"/"+type;
                  // window.open("https://publisher.7searchppc.com/auto-auth/" + str +"/" + type, "_blank");
                }
              }}>
              Switch to Advertiser
              </Button>
            :
            <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/switch-account`} >
              Become Advertiser
            </Link>
            }
          </div>
          <div style={{paddingLeft:42, marginTop:40}}>
            <h6 className="title mt-4 pl-1">Download the App</h6>
            <a href="https://play.google.com/store/apps/details?id=pub7.searchppc.com" target="_blank">
              <img className="logo-dark logo-img" src={Gplay} alt="logo" style={{marginLeft:5,marginTop:10}} />
            </a>
            <a href="https://apps.apple.com/in/app/7search-ppc-publisher/id6698887640" target="_blank">
              <img className="logo-dark logo-img" src={Apple} alt="logo" style={{marginLeft:15, marginTop:10}} />
            </a>
          </div>
          <div className="px-3">
            <Card className="bg-white mt-2">
              <Button color="primary" className="d-flex align-items-center mb-3 text-light">
                <Icon name="call-fill" style={{ fontSize: 22, color: "#fff", marginRight: "10px" }} /> +1 256 667
                3560
              </Button>
              <Button color="primary" className="d-flex align-items-center text-light m-0">
                <Icon name="emails" style={{ fontSize: 22, color: "#fff", marginRight: "10px" }} />{" "}
                support@7searchppc.com
              </Button>
            </Card>
          </div>
          </>
          }
        </SimpleBar>
      </div>
    </div>
  ); 
};

export default Sidebar;
