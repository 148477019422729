const menu = [
  // {
  //   icon: "dashlite",
  //   text: "Default Dashboard",
  //   link: "/",
  // },
  // {
  //   icon: "bitcoin-cash",
  //   text: "Crypto Dashboard",
  //   link: "/crypto",
  // },
  // {
  //   icon: "growth",
  //   text: "Analytics Dashboard",
  //   link: "/analytics",
  // },
  {
    icon: "home",
    text: "Dashboard",
    link: "/",
    extUrl: false,
    indx: "412",
  },
  // {
  //   heading: "Navigation",
  // },
  {
    icon: "globe",
    text: "Websites",
    link: "/websites",
    active: false,
    extUrl: false,
    indx: "413,414,416,418,420,422,424,426,428,430"
    // subMenu: [
    //   {
    //     text: "Project Cards",
    //     link: "/project-card",
    //   },
    //   {
    //     text: "Project List",
    //     link: "/project-list",
    //   },
    // ],
  },
  {
    icon: "wallet",
    text: "Payment",
    link: "/payment",
    active: false,
    extUrl: false,
    indx: "432,433,434,436,438"
  },
  {
    icon: "emails",
    text: "Inbox",
    link: "/inbox",
    active: false,
    extUrl: false,
    indx: "440"
  },
  {
    icon: "growth",
    text: "Statistics",
    link: "/report",
    active: false,
    extUrl: false,
    indx: "442"
  },
  {
    heading: "PERSONAL INFO",
    indx: "446,448,450,452,454,456"
  },
  {
    icon: "user-circle",
    text: "Profile",
    link: "/profile",
    active: false,
    extUrl: false,
    indx: "446,448,450,452,454,456"
  },
  {
    icon: "file-text",
    text: "KYC Document",
    link: "/user-kyc",
    active: false,
    extUrl: false,
    indx: "458"
  },
  // {
  //   icon: "na",
  //   text: "Blocked IPs",
  //   link: "/block-ip",
  //   active: false,
  //   extUrl: false,
  // },
  {
    icon: "account-setting-alt",
    text: "Support",
    link: "/support",
    active: false,
    extUrl: false,
    indx: "0"
  },
  // {
  //   icon: "help",
  //   text: "Help & Guide",
  //   link: "https://docs.7searchppc.com/advertiser.html",
  //   active: false,
  //   newTab: true,
  //   extUrl: true,
  // }
];
export default menu;
