import { create } from 'apisauce'

let sup = localStorage.getItem('sup_log');

  const api = create({
        baseURL: 'https://services.7searchppc.com/api',
        // baseURL: 'https://services.7searchppc.com/api',
        // baseURL: 'http://127.0.0.1:8000/api',
    headers: {  
        'X-API-KEY': '580eca75d1ffbacca33edc3278c092e9', 
        'Authorization': localStorage.getItem("authorization"),
        'Access-Control-Allow-Origin': true,
        'LOG-TYPE' : (sup) ? sup : 0
    },
  });

  const removeAuth = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("uid");
    localStorage.removeItem("fname");
    localStorage.removeItem("lname");
    localStorage.removeItem("email");
    localStorage.removeItem("wlt");
    localStorage.removeItem("perms");
    localStorage.removeItem("support_pin");
    localStorage.removeItem("sup_log");
    window.location.reload();
  }

  const loginUser = async (email, pass, browser) => {

    const res =  await api.post('/publisher/login', {
      email:email,
      password:pass,
      browser:browser,
      type:"publisher",
    });
    return res.data;
  }

  const validateToken = async (email, token, type, browser, emp = 0) => {

    const res =  await api.post('pub/user/validatetoken', {
      email:email,
      token:token,
      type:type,
      browser:browser,
      empid:emp
    });
      return res.data;
  }
  const switchToPub = async () => {
    api.setHeaders({
      'X-API-KEY': 'cR9i43OnLk7r9Ty44QespV2h',
      'Access-Control-Allow-Origin': true,
    })
    let uid = localStorage.getItem('uid');
    const res =  await api.post('/switch/user/account', {
      uid:uid
    });

    return res.data;
  }

  const regAsPub = async () => {
    api.setHeaders({
      'X-API-KEY': 'cR9i43OnLk7r9Ty44QespV2h',
      'Access-Control-Allow-Origin': true,
    })
    let uid = localStorage.getItem('uid');
    const res =  await api.post('/user/type/status/update', {
      uid:uid,
      utype:3
    });

    return res.data;
  }

  const validateForgetKey = async (uid, key) => {
    const res =  await api.post('/forget/user/password/validatekey', {
      uid:uid,
      authkey:key
    });
    return res.data;
  }

  const adWebsite = async (data) => {
    
    const res =  await api.post('/pub/website/add', data);
    return res.data;
  }

  const resubWebsite = async (data) => {
    const res =  await api.post('/user/pub/website/re-submit', data);
    return res.data;
  }

  const saveAdUnit = async (data) => {

    const res =  await api.post('pub/adunit/store', data);
    return res.data;
  }

  const getWebsiteList = async (stat, src, pg, lim) => {
    let uid = localStorage.getItem('uid');
    const res =  await api.post('pub/website/list', {
      uid: uid,
      page: pg,
      status:stat,
      src:src,
      lim: lim
    });
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
    // return res.data;
  }

  const getAdUnitList = async (wcode, pg, lim) => {
    let uid = localStorage.getItem('uid');
    const res =  await api.post('pub/website/adlist', {
      web_code: wcode,
      uid: uid,
      page: pg,
      lim: lim
    });
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
    // return res.data;
  }

  const getUserInfo = async ( uid ) => {
    const res = await api.get('/user/pub/info/'+uid)
    // return res.data.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data.data;
    }
  }


  const saveUserInfo = async ( uid, data ) => {
    const res = await api.post('/user/pub/update/'+uid, data)
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const getWallet = async ( uid ) => {
    const res = await api.post('/get/user/account/wallet',{'uid':uid})
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const uploadKyc = async (  data ) => {
    const res = await api.post('/user/pub/kyc', data)
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const kycInfo = async (  data ) => {
    const res = await api.post('user/pub/kyc/info', data)
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }


  const savePayoutDetail = async (  data ) => {
    const res = await api.post('pub/user/payoutmodestore', data)
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const payoutInfo = async (  data ) => {
    let uid = localStorage.getItem('uid');
    const res = await api.post('user/pub/payout/info', {
      uid:uid
    })
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }


  const getReportData = async (data) => {
    const res = await api.post('pub/user/report', data);
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const getWebList = async (data) => {
    const res = await api.post('pub/website/dropdown', data);
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const getAdUnitByWeb = async (data) => {
    const res = await api.post('pub/adunit/dropdown', data);
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

// ============================================================//
// ====================    DashBoard API   ===================//
// ==========================================================//

const dashboardGraph = async (data) => {
  const res = await api.post('/pub/user/dashboard', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


const topCampList = async (data) => {
  const res = await api.post('/user/dashboard/cmptop', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


const userPayStatus = async (data) => {
  const res = await api.post('/pub/user/payinfo', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const PayoutInvoice = async (transaction_id) => {
  const res = await api.post("/pub/user/payoutPubInvoice", {
    transaction_id: transaction_id,
  });
  if (res.data.code === 105) {
    removeAuth();
  } else {
    return res.data;
  }
};


const userBalanceData = async (data) => {
  const res = await api.post('/pub/user/balance', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}



const paymentHistory = async (data) => {
  const res = await api.post('/pub/user/payoutlist', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


const getPayoutList = async (data) => {
  const res = await api.post('/pub/user/payoutmethodlist', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getSelectedPayoutList = async (data) => {
  const res = await api.post('/pub/user/payoutselectedmethod', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getSupportData = async (data) => {
  const res = await api.post('pub/user/support/list', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const postSupportReq = async (data) => {
  const res = await api.post('pub/user/support/create', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getSupportChat = async (data) => {
  const res = await api.post('pub/user/support/info', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const postSupportChat = async (data) => {
  const res = await api.post('pub/user/support/chat', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const deleteSupportChat = async (data) => {
  const res = await api.post('pub/user/support/delete', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}




const LoginHistory = async (data) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/pub/user/login/log', {
    uid:uid,
    type:"publisher",
  });
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const reSubmitAdUnit = async (data) => {
  const res = await api.post('pub/adunit/resubmit',data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}



const getNotification = async (pg, lim) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/pub/user/notification/user_id', {
    user_id:uid,
    page:pg,
    lim:lim,
  });
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getNotificationShort = async (data) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/pub/user/notification/unreadnoti', {
    user_id:uid
  });
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const updateNotification = async (noti_id) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/pub/user/notification/read', {
    notifuser_id:noti_id,
    uid:uid
  });
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


const changePassword = async (data) => {
  const res = await api.post('/pub/user/change_password', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const adUnitInfo = async (data) => {
  const res = await api.post('pub/adunit/editinfo', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const updateAdUnit = async (data) => {
  const res = await api.post('pub/adunit/edit', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

/* ==============================================================>>>>>
=================================================================>>>>>
||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
----------------------------------------------------------------------
|             7Search Publiser API Code : End                        |
----------------------------------------------------------------------
======================================================================*/



  
  const getWalletAmount = async ( uid ) => {  
    const res =  await api.get('/user/wallet/show/'+uid);
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }



      
  const getCountryList = async (  ) => {
    const res = await api.get('/country/index')
    return res.data;
  }
   
  const getCategoryList = async (  ) => {
    let uid = localStorage.getItem('uid');
    const res = await api.post('/pub/category', { uid: uid})
    return res.data;
  }
     
  const CampaignList = async ( type, sts, src, pg, lim ) => {
    let uid = localStorage.getItem('uid');
    const res = await api.post('/user/campaign/list', {
      uid:uid,
      src:src,
      type:type,
      status:sts,
      page:pg,
      lim:lim
    });
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

  const deleteCampaign = async ( uid, cid ) => {
    const res = await api.post('/user/campaign/delete', {
      uid:uid,
      cid:cid
    })
    // return res.data;
    if(res.data.code === 403) {
      removeAuth();
    } else {
      return res.data;
    }
  }

 

// ============================================================//
// =================    BLOCK IP REQUEST    ==================//
// ==========================================================//

const submitIpRequest = async (data) => {
  
  const res = await api.post('/user/ip/request/create', data);
  return res.data;

}

const getIpList = async ( lim, pg) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/user/ip/list', {
    uid:uid,
    limit:lim,
    page:pg
  });
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


// http://192.168.18.166/7sapp/7searchBackend/public/api/user/support/list

// ========================================================//
//  --------          REPORT API's          -------------//
// =====================================================//



const getUserCampIdData = async (data) => {
  const res = await api.post('/user/ad_type/camp', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getInvoiceData = async (data) => {
  const res = await api.post('/user/transaction/view', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const getPubData = async (data) => {
  const res = await api.post('/user/pub/website/add', data);
  // return res.data;
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}


const forgetPasswordApi = async (data) => {
  const res = await api.post('/pub/forget/user/password', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const updatePasswordApi = async (data) => {
  const res = await api.post('/pub/forgetpassword/user/submit', data);
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

const updateNotiToken = async (token) => {
  let uid = localStorage.getItem('uid');
  const res = await api.post('/user/pub/updatetoken', {
    uid:uid,
    noti_token:token
  });
  if(res.data.code === 403) {
    removeAuth();
  } else {
    return res.data;
  }
}
const getMessengerList = async () => {
  const res = await api.get('/messenger_list')
  return res.data;
}

const checkMeta = async (data) => {
  const res = await api.post('pub/website/check-meta',data)
  return res.data;
}
const checkWebsite = async (data) => {
  const res = await api.post('pub/website/check-site-url',data)
  return res.data;
}
const addWire = async (data) => {
  const res = await api.post('pub/user/addWireTransferGateway',data)
  return res.data;
}

// check-email-get
const getUidByEmail = async (email) => {
  const res = await api.post('check-email-get',{email: email})
  return res.data;
}

// publisher feedback
const postFeedbackReq = async (data) => {
  const res = await api.post('/pub/user/feedback/create', data);
  if(res.data.code == 403) {
    removeAuth();
  } else {
    return res.data;
  }
}
const delProfileRequest = async (data) => {
  const res = await api.post('pub/user/request_delete_remark',data)
  return res.data;
}

// profile Logs
const profileLogs = async (pg, itemLim, type) => {
  const res = await api.post('/profile/log-list', {
    uid: localStorage.getItem('uid'),
    type: type,
    page: pg,
    lim: itemLim
  });
  if(res.data.code == 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

// Get publisher assigned agent details
const getAssignedAgentDetails = async (data) => {
  const res = await api.post('pub/user/assigned-agent', {
    uid: data.uid
  });
  if(res.data.code == 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

// Get slider content for header
const sliderContentHeader = async (data) => {
  const res = await api.post('/pub/user/header-message', {
    uid: data
  });
  if(res.data.code == 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

// Get popup content
const getPopupContent = async (uid, type) => {
  const res = await api.post('/pub/user/popup-message-list', {
    uid: uid,
    popup_type: type
  });
  if(res.data.code == 403) {
    removeAuth();
  } else {
    return res.data;
  }
}

export  { 
  getPopupContent,
  sliderContentHeader,
  getAssignedAgentDetails,
  getPubData,
  loginUser, 
  validateToken,
  getUserInfo, 
  saveUserInfo,
  getWalletAmount,
  getCategoryList,
  getCountryList,
  CampaignList,
  deleteCampaign,
  getNotification,
  updateNotification,
  submitIpRequest,
  getIpList,
  paymentHistory,
  getNotificationShort,
  LoginHistory,
  changePassword,
  dashboardGraph,
  topCampList,
  getSupportData,
  postSupportReq,
  getSupportChat,
  postSupportChat,
  deleteSupportChat,
  getReportData,
  getUserCampIdData,
  getInvoiceData,
  forgetPasswordApi,
  updatePasswordApi,
  adUnitInfo,
  updateAdUnit,
  getMessengerList,
  
  // ============================== //
  //    Publisher Use APi
  // =============================//

  resubWebsite,
  adWebsite,
  saveAdUnit,
  getWebsiteList,
  getAdUnitList,
  uploadKyc,
  kycInfo,
  savePayoutDetail,
  payoutInfo,
  getWebList,
  getAdUnitByWeb,
  userPayStatus,
  PayoutInvoice,
  userBalanceData,
  getPayoutList,
  getSelectedPayoutList,
  reSubmitAdUnit,
  validateForgetKey,
  updateNotiToken,
  switchToPub,
  regAsPub,
  checkMeta,
  checkWebsite,
  getWallet,
  addWire,
  getUidByEmail,
  postFeedbackReq,
  delProfileRequest,
  profileLogs
}