const numberToWords = (num) => {
  if (num === 0) return "Zero Dollars";

  const belowTwenty = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const thousands = ["", "Thousand", "Million", "Billion", "Trillion"];

  const getHundreds = (n) => {
    let str = "";
    if (n > 99) {
      str += belowTwenty[Math.floor(n / 100)] + " Hundred ";
      n %= 100;
    }
    if (n > 19) {
      str += tens[Math.floor(n / 10) - 2] + " ";
      n %= 10;
    }
    if (n > 0) {
      str += belowTwenty[n] + " ";
    }
    return str;
  };

  const convert = (n) => {
    let words = "";
    let i = 0;
    while (n > 0) {
      if (n % 1000 !== 0) {
        words = getHundreds(n % 1000) + thousands[i] + " " + words;
      }
      n = Math.floor(n / 1000);
      i++;
    }
    return words.trim();
  };

  const dollars = Math.floor(num);
  const cents = Math.round((num - dollars) * 100);

  let dollarWords = convert(dollars);
  let centWords = convert(cents);

  dollarWords = dollarWords === "" ? "Zero" : dollarWords;
  centWords = centWords === "" ? "Zero" : centWords;

  return `${dollarWords} Dollar${dollars !== 1 ? "s" : ""}${
    cents > 0 ? " and " + centWords + " Cent" + (cents !== 1 ? "s" : "") : ""
  }`;
};

export default numberToWords;
