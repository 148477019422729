import React, { useState, useEffect, useContext } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
// import topbg7 from "../../assets/images/topbg7.png";
// import logowire from "../../assets/images/logo.png";
import numberToWords from "../../components/numberToWords";

import {
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  BlockDes,
} from "../../components/Component";
import { Button, Card } from "reactstrap";
import { useParams } from "react-router";
import Loader from "../../app/Loader";
import Moment from "react-moment";
import { PayoutInvoice } from "../../app/api";
import AppContext from "../../context/AppContext";

const PayoutInvoiceView = () => {
  const { transaction_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [cdata, setData] = useState(null);
  const [bankData, setBankData] = useState(null);
  const [amt, setAmt] = useState(null);
  const ctx = useContext(AppContext);

  const getReleasedPayout = async () => {
    setLoading(true);
    const res = await PayoutInvoice(atob(transaction_id));
    if (res.data) {
      if (
        res.data.payout_method.toLowerCase() === "wire transfer" ||
        res.data.payout_method.toLowerCase() === "bank"
      ) {
        let bankDetails = JSON.parse(res.data.payout_id);
        setBankData(bankDetails);
      }
      setData(res.data);
    }
    if (res) {
      setAmt(res);
    }
    ctx.dispatch({
      type: "wlt",
      wlt: res.wallet,
    });
    setLoading(false);
  };

  const NumberToWords = ({ net_amt }) => {
    return <span>{numberToWords(net_amt)}</span>;
  };

  useEffect(() => {
    getReleasedPayout();
  }, []);

  return (
    <React.Fragment>
      <Head title="Payout Invoice"></Head>
      <Content>
        <Loader visible={loading} />
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>
                Invoice{" "}
                <strong className="text-primary small">
                  #{cdata?.invoice_number == 0 ? "N/A" : cdata?.invoice_number}
                </strong>
              </BlockTitle>
              <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    Created At:
                    <span className="text-base">
                      <Moment format="DD-MM-YYYY">
                        {cdata !== null ? cdata.release_date : "0"}
                      </Moment>
                    </span>
                  </li>
                </ul>
              </BlockDes>
              <div className="invoice-action">
                <Link to={`${process.env.PUBLIC_URL}/payment`}>
                  <Button
                    color="light"
                    outline
                    className="bg-white d-none d-sm-inline-flex ml-2"
                  >
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                </Link>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Card className="mx-auto" style={{ width: "1036px" }}>
          <div className="d-flex justify-content-end">
            <Link
              to={`${process.env.PUBLIC_URL}/payout-invoice-print/${transaction_id}`}
              target="_blank"
              className="btn btn-white btn-outline-light m-3">
              <Icon name="printer-fill"></Icon> <span>Print</span>
            </Link>
          </div>
          <div
            id="invoiceContent"
            style={{
              width: "1000px",
              margin: "0 auto",
              color: "#666",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "1.6em",
              backgroundColor: "#ffffff",
            }}
          >
            <table
              borderless
              style={{
                width: "100%",
                padding: "0px",
                margin: "0px",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundImage:
                      "url(https://services.7searchppc.com/public/images/mail/topbg7.png)",
                    backgroundRepeat: "no-repeat",
                    height: "130px",
                  }}
                >
                  <td
                    style={{
                      position: "relative",
                      textAlign: "left",
                      padding: "25px 30px",
                      fontWeight: "bold",
                      color: "#fff",
                      textTransform: "uppercase",
                      fontSize: "56px",
                      lineHeight: "1em",
                      letterSpacing: "4px",
                    }}
                  >
                    Invoice
                  </td>
                  <td style={{ padding: "25px 30px", textAlign: "right" }}>
                    <img
                      src="https://services.7searchppc.com/public/images/mail/logo.png"
                      alt="Logo"
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      paddingTop: "20px",
                      verticalAlign: "top",
                    }}
                    className="px-3"
                  >
                    <table
                      borderless
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "15px 16px 15px 6px",
                              textAlign: "left",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#929292",
                                margin: "0",
                              }}
                            >
                              Invoice Number
                            </p>
                            <p
                              style={{
                                fontSize: "17px",
                                color: "#282828",
                                margin: "10px 0 5px",
                                fontWeight: "400",
                              }}
                            >
                              <b>
                                {cdata?.invoice_number == 0
                                  ? "N/A"
                                  : `#${cdata?.invoice_number}`}
                              </b>
                            </p>
                          </td>
                          <td
                            style={{ padding: "15px 16px", textAlign: "left" }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#929292",
                                margin: "0",
                              }}
                            >
                              Due Date
                            </p>
                            <p
                              style={{
                                fontSize: "17px",
                                color: "#282828",
                                margin: "10px 0 5px",
                                fontWeight: "400",
                              }}
                            >
                              <b>
                                {cdata && (
                                  <Moment format="DD MMM YYYY">
                                    {cdata.release_created_at}
                                  </Moment>
                                )}
                              </b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "15px 16px 15px 6px",
                              textAlign: "left",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#929292",
                                margin: "0",
                              }}
                            >
                              Payment Method
                            </p>
                            <p
                              style={{
                                fontSize: "17px",
                                color: "#282828",
                                margin: "10px 0 5px",
                                fontWeight: "400",
                              }}
                            >
                              <b>{cdata && cdata.payout_method}</b>
                            </p>
                          </td>
                          <td
                            style={{ padding: "15px 16px", textAlign: "left" }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#929292",
                                margin: "0",
                              }}
                            >
                              Release Date
                            </p>
                            <p
                              style={{
                                fontSize: "17px",
                                color: "#282828",
                                margin: "10px 0 5px",
                                fontWeight: "400",
                              }}
                            >
                              <b>
                                {cdata && (
                                  <Moment format="DD MMM YYYY">
                                    {cdata.release_date}
                                  </Moment>
                                )}
                              </b>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      paddingTop: "38px",
                      verticalAlign: "top",
                    }}
                    className="px-3"
                  >
                    <p
                      style={{
                        color: "#929292",
                        fontSize: "16px",
                        margin: "0 0 5px",
                      }}
                    >
                      Paid to:
                    </p>
                    <p
                      style={{
                        color: "#282828",
                        fontSize: "22px",
                        margin: "0 0 5px",
                      }}
                    >
                      {cdata?.first_name || ""} {cdata?.last_name || ""}
                    </p>
                    <p
                      style={{
                        color: "#282828",
                        fontSize: "15px",
                        margin: "0 0 5px",
                      }}
                    >
                      {cdata?.address_line1 && <>{cdata.address_line1} </>}
                      {cdata?.address_line2 && (
                        <>
                          {cdata.address_line2}
                          {", "}
                        </>
                      )}
                      {(cdata?.city || cdata?.state) && (
                        <>
                          {cdata.city && cdata.state
                            ? `${cdata.city}, ${cdata.state}`
                            : cdata.city || cdata.state}
                          {", "}
                        </>
                      )}
                      {cdata?.country && (
                        <>{`${cdata.country}${
                          cdata.postcode ? ` (${cdata.postcode})` : ""
                        }`}</>
                      )}
                    </p>
                    <p
                      style={{
                        color: "#282828",
                        fontSize: "15px",
                        margin: "0 0 5px",
                      }}
                    >
                      {" "}
                      {cdata?.email || ""}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ paddingTop: "20px" }}
                    className="px-3"
                  >
                    <table
                      borderless
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #3B82F6",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            borderTop: "2px solid #3B82F6",
                            color: "#929292",
                            textAlign: "left",
                          }}
                        >
                          <th
                            style={{
                              padding: "15px 6px",
                              lineHeight: "1.55em",
                              fontSize: "16px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            Description
                          </th>
                          <th
                            style={{
                              padding: "15px 6px",
                              lineHeight: "1.55em",
                              fontSize: "16px",
                              fontWeight: "normal",
                              textAlign: "right",
                            }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              borderTop: "2px solid #3B82F6",
                              padding: "15px 6px",
                              color: "#282828",
                            }}
                          >
                            Advertisement Services
                          </td>
                          <td
                            style={{
                              borderTop: "2px solid #3B82F6",
                              padding: "15px 6px",
                              color: "#282828",
                              textAlign: "right",
                            }}
                          >
                            $
                            {cdata &&
                            cdata.amount !== undefined &&
                            cdata.amount !== null
                              ? cdata.amount.toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ paddingTop: "30px", paddingBottom: "30px" }}
                    className="px-3"
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#929292",
                        margin: "0 0 5px",
                      }}
                    >
                      Amount in words:
                      <br />
                      <span style={{ color: "#000" }}>
                        {amt !== null && amt.net_amount !== null ? (
                          <span>
                            <NumberToWords net_amt={amt.net_amount} /> Only
                          </span>
                        ) : (
                          "0"
                        )}
                      </span>
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#282828",
                        margin: "0 0 35px",
                      }}
                    ></p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#929292",
                        margin: "0 0 5px",
                      }}
                    >
                      Details for {`${cdata && cdata.payout_method}`}:
                    </p>
                    {cdata && (
                      <>
                        {/* Bank account payout details (payout_id === 4) */}
                        {cdata.payout_method.toLowerCase() ===
                          "wire transfer" ||
                        (cdata.payout_method.toLowerCase() === "bank" &&
                          !["NULL", null].includes(bankData.bank_name) &&
                          !["NULL", null].includes(
                            bankData.account_holder_name
                          ) &&
                          !["NULL", null].includes(bankData.account_number) &&
                          !["NULL", null].includes(bankData.ifsc_code) &&
                          !["NULL", null].includes(bankData.swift_code) &&
                          !["NULL", null].includes(bankData.iban_code)) ? (
                          <>
                            {[
                              {
                                label: "Account Holder’s Name",
                                value: bankData && bankData.account_holder_name,
                              },
                              {
                                label: "Bank Name",
                                value: bankData && bankData.bank_name,
                              },
                              {
                                label: "Account Number",
                                value: bankData && bankData.account_number,
                              },
                              {
                                label: "IFSC Code",
                                value: bankData && bankData.ifsc_code,
                              },
                              {
                                label: "Swift Code",
                                value: bankData && bankData.swift_code,
                              },
                              {
                                label: "IBAN Code",
                                value: bankData && bankData.iban_code,
                              },
                            ].map(({ label, value }) => (
                              <p key={label} style={{ margin: "0" }}>
                                <span
                                  style={{ fontSize: "1rem", color: "#000" }}
                                >
                                  {label}:
                                </span>{" "}
                                <span
                                  style={{ fontWeight: "600", color: "#000" }}
                                >
                                  {value}
                                </span>
                              </p>
                            ))}
                          </>
                        ) : (
                          <>
                            {[
                              {
                                label: "Account Holder’s Name",
                                value: `${cdata.first_name} ${cdata.last_name}`,
                              },
                              {
                                label: "Account ID",
                                value: cdata && cdata.payout_id,
                              },
                            ].map(({ label, value }) => (
                              <p key={label} style={{ margin: "0" }}>
                                <span
                                  style={{ fontSize: "1rem", color: "#000" }}
                                >
                                  {label}:
                                </span>{" "}
                                <span
                                  style={{ fontWeight: "600", color: "#000" }}
                                >
                                  {value}
                                </span>
                              </p>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </td>
                  <td
                    style={{
                      paddingTop: "30px",
                      paddingBottom: "30px",
                      verticalAlign: "top",
                    }}
                    className="px-3"
                  >
                    <table
                      borderless
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #3B82F6",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "15px 6px",
                              color: "#282828",
                              textAlign: "left",
                            }}
                          >
                            Tax and Fees
                          </td>
                          <td
                            style={{
                              padding: "15px 6px",
                              color: "#282828",
                              textAlign: "right",
                            }}
                          >
                            $
                            {amt && amt.tax_deduction
                              ? amt.tax_deduction.toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderTop: "2px solid #3B82F6",
                              padding: "15px 6px",
                              color: "#000",
                              fontWeight: "600",
                              textAlign: "left",
                            }}
                          >
                            Net Amount
                          </td>
                          <td
                            style={{
                              borderTop: "2px solid #3B82F6",
                              padding: "15px 6px",
                              fontWeight: "600",
                              color: "#000",
                              textAlign: "right",
                            }}
                          >
                            $
                            {amt &&
                            amt.net_amount !== undefined &&
                            amt.net_amount !== null
                              ? amt.net_amount.toFixed(2)
                              : "0.00"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ height: "20px", borderTop: "2px solid #dbdfea" }}
                  >
                    {" "}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      color: "#111",
                      marginTop: "30px",
                      paddingBottom: "30px",
                      textAlign: "left",
                      fontStyle: "normal",
                    }}
                    className="px-3"
                  >
                    <p style={{ color: "#929292" }}>Terms & Conditions:</p>
                    <ol
                      style={{ listStyleType: "decimal", paddingLeft: "20px" }}
                    >
                      <li>
                        This transaction is governed by the terms and conditions
                        agreed upon by the user at the time of making the
                        payment.
                      </li>
                      <li>
                        The amount, once added, can only be used for running ad
                        campaigns on 7SearchPPC.
                      </li>
                      <li>
                        The amount, once added, cannot be refunded to the user’s
                        bank account or any other personal wallet.
                      </li>
                      <li>
                        In case some amount of money has been debited from the
                        wallet accidentally without the user’s consent, it will
                        be transferred back to the wallet within a few hours of
                        a claim.
                      </li>
                      <li>
                        Users will never be charged more than the required
                        amount to run the campaign.
                      </li>
                      <li>
                        The amount added to the wallet will be calculated as per
                        the USD.
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ height: "20px", borderTop: "2px solid #dbdfea" }}
                  >
                    {" "}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <table
                      borderless
                      style={{
                        width: "94%",
                        borderCollapse: "collapse",
                        margin: "0 auto",
                      }}
                      border="0"
                    >
                      <tbody>
                        <tr>
                          <td style={{ margin: "0", padding: "0" }}>
                            <img
                              src="https://services.7searchppc.com/public/images/mail/logofooter.png"
                              alt="Logo"
                            />
                          </td>
                          <td style={{ margin: "0", padding: "0" }}>
                            <p
                              style={{
                                width: "16px",
                                height: "80px",
                                borderLeft: "2px solid #3B82F6",
                              }}
                            ></p>
                          </td>
                          <td>
                            <p
                              style={{
                                color: "#282828",
                                fontWeight: "700",
                                fontSize: "32px",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              Thank you for doing business with us.
                            </p>
                            <p
                              style={{
                                color: "#929292",
                                fontSize: "18px",
                                margin: "0",
                                padding: "5px 0 0",
                              }}
                            >
                              We look forward to working with you again.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default PayoutInvoiceView;
